h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  text-align: center;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
}

p {
  margin-bottom: 10px;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}

a {
  color: #fff;
  text-decoration: underline;
}

.body {
  background-color: #171224;
  letter-spacing: 2px;
}

.body.bg {
  padding-bottom: 0px;
  background-color: #0f0c14;
}

.brand {
  z-index: 99;
  width: 82px;
  height: 100px;
  margin-right: 0px;
  background-image: url('../images/Logo.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.brand:hover {
  opacity: 0.8;
}

.brand.test {
  width: 100px;
  margin-top: 20px;
  margin-left: 20px;
  background-image: url('../images/AG-final-03-Arcade-Glyphs.png');
  background-size: contain;
}

.navbar {
  position: fixed;
  top: 20px;
  z-index: 98;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  padding-right: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(22, 0, 75, 0);
}

.newlink {
  display: inline-block;
  height: 72px;
  padding-top: 28px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.newlink:hover {
  border-bottom: 3px solid #fff;
  text-decoration: none;
}

.newlink.w--current {
  height: 100%;
  padding-top: 28px;
  border-bottom: 3px solid #fff;
  color: #fff;
}

.newlink.w--current:hover {
  text-decoration: none;
}

.newlink.w--current:visited {
  border-bottom: 2px none #fff;
}

.navmenu {
  z-index: 5;
  display: block;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.header {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto;
  margin-bottom: 0px;
  padding: 0px 0px 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(4, 8, 36, 0)), color-stop(32%, rgba(4, 8, 36, 0.74)), to(#0f0c14)), url('../images/Bg-01-Artboard-1.png'), url('../images/91657.jpg');
  background-image: linear-gradient(180deg, rgba(4, 8, 36, 0), rgba(4, 8, 36, 0.74) 32%, #0f0c14), url('../images/Bg-01-Artboard-1.png'), url('../images/91657.jpg');
  background-position: 0px 0px, 50% 0%, 50% 50%;
  background-size: auto, cover, 100% 100%;
  background-repeat: repeat, no-repeat, no-repeat;
  background-attachment: scroll, scroll, scroll;
}

.header.test {
  overflow: visible;
  padding: 160px 56px 60px;
  background-color: #331730;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(4, 8, 36, 0)), color-stop(50%, rgba(4, 8, 36, 0.74)), to(#0f0c14)), url('../images/Bg-01-Artboard-1.png');
  background-image: linear-gradient(180deg, rgba(4, 8, 36, 0), rgba(4, 8, 36, 0.74) 50%, #0f0c14), url('../images/Bg-01-Artboard-1.png');
  background-position: 0px 0px, 50% 0%;
  background-size: auto, cover;
  background-repeat: repeat, repeat;
  background-attachment: scroll, scroll;
}

.heading {
  position: relative;
  z-index: 5;
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 56px;
  line-height: 64px;
  font-style: normal;
  text-align: center;
}

.heading.leftalign {
  font-size: 7vw;
  line-height: 7vw;
  text-align: left;
}

.heading.leftalign.hidden {
  display: none;
}

.containercontent {
  position: relative;
  left: auto;
  z-index: 4;
  margin-top: 0px;
  padding-top: 140px;
}

.containercontent.list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 40px 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.containercontent.page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 40px 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.containercontent.creditspage {
  display: block;
  padding: 0px 40px 120px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.gamecontainer {
  position: relative;
  z-index: 2;
  display: block;
  width: 300px;
  height: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.gamecontainer.list {
  width: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.buttoncontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button {
  width: 100%;
  margin-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-image: linear-gradient(233deg, #6e01cd, #e30159);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: VT323, sans-serif;
  font-size: 20px;
  text-align: center;
}

.button:hover {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.button.nav {
  position: relative;
  z-index: 99;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 16px;
}

.button.nav.mobile {
  display: none;
}

.button.compact {
  display: inline-block;
  width: auto;
  min-width: 200px;
  font-size: 16px;
  text-transform: uppercase;
}

.button.large {
  min-width: 230px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button.large.mintbutton {
  width: auto;
}

.button.large.claimprize1button {
  display: block;
  width: 230px;
}

.button.large.claimprize2button {
  display: block;
  width: 230px;
}

.button.large.claimprize3button {
  display: block;
  width: 230px;
}

.button.large.claimprize4button {
  display: block;
  width: 230px;
}

.button.large.gotoglyph {
  display: block;
  margin-bottom: 10px;
}

.button.large.gotothrophies {
  display: block;
  margin-top: 32px;
  margin-bottom: 10px;
}

.button.large.mint5button {
  width: auto;
  min-width: 0px;
}

.button.bottom {
  display: block;
  width: auto;
  max-width: 230px;
  min-width: 230px;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
  text-transform: uppercase;
}

.button.banner {
  position: relative;
  z-index: 99;
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 15px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

.button.banner:hover {
  color: #fff;
}

.button.banner.mint5button {
  margin-left: 12px;
}

.button.opensealink {
  position: relative;
  z-index: 99;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-width: 230px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#221d42), to(#221d42));
  background-image: linear-gradient(180deg, #221d42, #221d42);
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.contentsection2 {
  position: relative;
  overflow: visible;
  margin-top: -20px;
  margin-bottom: 0px;
  padding: 80px 0px 60px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(15, 13, 46, 0.74)), to(rgba(15, 13, 46, 0.74))), url('../images/Bg-01-Artboard-1.png');
  background-image: linear-gradient(180deg, rgba(15, 13, 46, 0.74), rgba(15, 13, 46, 0.74)), url('../images/Bg-01-Artboard-1.png');
  background-position: 0px 0px, 50% 100%;
  background-attachment: scroll, scroll;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.contentsection2.rules {
  margin-top: 0px;
  margin-bottom: 120px;
  background-image: none;
}

.sectionheading {
  position: relative;
  z-index: 4;
  margin-bottom: 30px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
}

.paragraph {
  max-width: none;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
}

.paragraph.centeraligned {
  position: relative;
  z-index: 4;
  color: #a0a5c4;
  text-align: center;
}

.paragraph.centeraligned.bottompadding {
  margin-bottom: 40px;
}

.animation {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -20%;
  z-index: 3;
  display: none;
  width: 100%;
  height: auto;
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
  opacity: 0.4;
}

.sectioncontent {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px 60px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: transparent;
}

.sectioncontent.rulessection {
  margin-top: 60px;
  padding-top: 0px;
  padding-right: 60px;
  padding-left: 60px;
}

.footer {
  position: relative;
  z-index: 6;
  margin-bottom: 0px;
  padding-top: 60px;
  padding-bottom: 0px;
  background-color: #16004b;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(28, 18, 63, 0.5)), to(rgba(28, 18, 63, 0.5))), url('../images/Bg-01-Artboard-1.png');
  background-image: linear-gradient(180deg, rgba(28, 18, 63, 0.5), rgba(28, 18, 63, 0.5)), url('../images/Bg-01-Artboard-1.png');
  background-position: 0px 0px, 50% 0%;
  background-size: auto, auto;
}

.topfooter {
  display: block;
  padding-top: 20px;
  padding-bottom: 80px;
}

.bottomfooter {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #0c002a;
}

.footercolumn {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image {
  position: relative;
  display: block;
}

.heading2 {
  margin-top: 0px;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.listitem2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.containertopfooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.textblock2 {
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 18px;
  text-align: center;
}

.counter {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99;
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-color: transparent;
  font-family: VT323, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 21px;
  font-style: italic;
  font-weight: 400;
  text-align: left;
}

.navlinks {
  position: relative;
  left: 32px;
  height: 72px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0px;
  text-align: center;
}

.spacer {
  display: none;
}

.backgroundvideo {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
  object-fit: fill;
}

.backgroundvideo.hidden {
  display: none;
}

.overlay {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  display: none;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(16, 15, 27, 0.85);
}

.lottieanimation {
  height: auto;
}

.list {
  padding-left: 0px;
}

.footerlink {
  display: inline-block;
  padding-right: 0px;
  padding-left: 0px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.footerlink:hover {
  text-decoration: underline;
}

.footerlink.w--current {
  height: 100%;
  color: #fff;
}

.footerlink.w--current:visited {
  border-bottom: 2px none #fff;
}

.main {
  position: relative;
  z-index: 4;
  background-color: #0c0c0c;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(17%, #0f0b16), color-stop(72%, rgba(18, 13, 31, 0.78)), to(rgba(18, 13, 31, 0.78))), url('../images/spacebg.jpeg');
  background-image: linear-gradient(180deg, #0f0b16 17%, rgba(18, 13, 31, 0.78) 72%, rgba(18, 13, 31, 0.78)), url('../images/spacebg.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, 600px;
}

.main.test {
  background-color: #16151b;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(17%, #0f0b16), color-stop(72%, rgba(18, 13, 31, 0.78)), to(rgba(18, 13, 31, 0.78))), url('../images/spacebg.jpeg');
  background-image: linear-gradient(180deg, #0f0b16 17%, rgba(18, 13, 31, 0.78) 72%, rgba(18, 13, 31, 0.78)), url('../images/spacebg.jpeg');
  background-size: auto, 600px;
}

.logofooter.w--current {
  opacity: 0.8;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.gamelistitem {
  position: relative;
  display: none;
  width: 100%;
  max-width: none;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  float: left;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.06);
  list-style-type: none;
}

.gameinfo {
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.heading3 {
  position: relative;
  display: block;
  margin-top: -12px;
  margin-bottom: 0px;
  float: none;
  clear: left;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 36px;
}

.heading3.small {
  margin-top: 0px;
  font-size: 1.7vw;
  line-height: 2.2vw;
}

.gamelist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: auto;
  max-width: 1200px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ongoinglabel {
  padding-right: 4px;
  padding-left: 4px;
  background-color: #fc0;
  color: #070707;
  text-transform: uppercase;
}

.gameinformation {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  margin-right: 12px;
  margin-bottom: 20px;
  float: left;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 20px;
}

.gameinformation.claimedlabel {
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  float: left;
}

.gameinformation.unclaimedlabel {
  padding-right: 0px;
  padding-left: 0px;
}

.listicon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  float: left;
}

.listicon.pts {
  background-image: url('../images/Icons.png');
  background-position: 0px 0px;
  background-size: cover;
}

.listicon.next {
  background-image: url('../images/Icons-3.png');
  background-position: 0px 0px;
  background-size: cover;
}

.listicon.time {
  background-image: url('../images/Icons-2.png');
  background-position: 0px 0px;
  background-size: cover;
}

.listicon.availability {
  background-image: url('../images/availability.png');
  background-position: 0px 0px;
  background-size: cover;
}

.listicon.winner {
  background-image: url('../images/game.gif');
  background-position: 0px 0px;
  background-size: cover;
}

.listitem {
  display: block;
  height: 24px;
  margin-bottom: 12px;
  float: none;
  clear: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: VT323, sans-serif;
  color: #fff;
}

.listitem.loading {
  width: 250px;
  background-color: #221d42;
}

.listitem.loading.short {
  width: 170px;
}

.listitem.winneritem {
  display: none;
}

.listitemtext {
  position: relative;
  max-width: none;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  float: left;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  text-transform: uppercase;
}

.listitemtext.lowercased {
  text-transform: capitalize;
}

.list3 {
  margin-top: 0px;
  margin-bottom: 31px;
  padding-left: 0px;
}

.gameasset {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 194px;
  height: 300px;
  margin-right: auto;
  margin-left: auto;
  background-color: #221d42;
}

.gameasset._2 {
  width: 194px;
  border: 1px none #000;
  background-image: url('../images/Game-36.svg');
}

.gameasset.loading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.gameoverlabel {
  display: block;
  padding-right: 4px;
  padding-left: 4px;
  float: none;
  clear: none;
  background-color: #ff004c;
  color: #070707;
  text-transform: uppercase;
}

.headingm {
  margin-top: 56px;
  margin-bottom: 20px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 48px;
  line-height: 56px;
}

.buttonscontainer {
  display: block;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.vspaces {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 32px;
  float: left;
}

.contentsection {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(17%, #0f0b16), color-stop(72%, rgba(18, 13, 31, 0.78)), to(rgba(18, 13, 31, 0.78))), url('../images/spacebg.jpeg');
  background-image: linear-gradient(180deg, #0f0b16 17%, rgba(18, 13, 31, 0.78) 72%, rgba(18, 13, 31, 0.78)), url('../images/spacebg.jpeg');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, 600px;
}

.upsellbanner {
  position: -webkit-sticky;
  position: sticky;
  left: 0%;
  top: 100px;
  right: 0%;
  bottom: auto;
  z-index: 6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 800px;
  height: 72px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #5d00b4;
  background-image: none;
  background-position: 0px 0px;
  -webkit-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  color: #fff;
}

.infobannercopy {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: VT323, sans-serif;
  font-size: 20px;
  text-align: center;
}

.infobannercopy.leftaligned {
  margin-right: 20px;
  text-align: left;
}

.gamedescription {
  display: block;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 0px 0vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breakingbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 99;
  height: 20px;
  margin-top: 0px;
  padding-right: 8px;
  border-style: none;
  border-width: 2px;
  border-color: #000;
  background-color: #cebd06;
}

.hp {
  position: absolute;
  z-index: 1;
  width: 1%;
  height: 100%;
  margin-left: -8px;
  background-color: #b80d43;
  -webkit-transform: skew(-28deg, 0deg);
  -ms-transform: skew(-28deg, 0deg);
  transform: skew(-28deg, 0deg);
}

.aglogo {
  position: relative;
  width: auto;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.logocontainer {
  display: block;
  width: 320px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image4 {
  position: relative;
  margin-right: 13px;
  float: left;
}

.textblock3 {
  float: left;
  text-transform: uppercase;
}

.navbackground {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-image: url('../images/Bg-01-Artboard-1.png');
  background-position: 50% 90%;
  background-size: 102%;
}

.decoration {
  position: relative;
  left: auto;
  top: 0%;
  right: -360px;
  bottom: 0%;
  z-index: 1;
  width: 100%;
  height: 490px;
  background-image: url('../images/ball-Asset-1.png');
  background-position: 100% 0%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}

.headercontainer {
  padding-top: 100px;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-2.two {
  position: relative;
  bottom: 0px;
  margin-bottom: 0px;
}

.rich-text-block {
  display: none;
  margin-top: 55px;
  color: #fff8f8;
}

.buttonsecondary {
  width: 100%;
  margin-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-image: linear-gradient(233deg, #6e01cd, #e30159);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: VT323, sans-serif;
  font-size: 20px;
  text-align: center;
}

.buttonsecondary:hover {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.buttonsecondary.large {
  min-width: 230px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  background-color: #221d42;
  background-image: none;
  text-transform: uppercase;
}

.buttonsecondary.large.openseabutton {
  width: auto;
}

.buttonsecondary.large.viewonopensea {
  display: none;
  width: 230px;
}

.buttonsecondary.large.modalaction {
  margin-bottom: 10px;
  background-color: #3d385e;
}

.buttonsecondary.large.modalaction.paddingtop {
  margin-top: 10px;
}

.buttonsecondary.large.opensealink {
  width: auto;
}

.section {
  position: relative;
  z-index: 5;
  display: block;
  max-width: 1100px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.backgrounddecorations {
  position: absolute;
  top: 300px;
  right: 0px;
  overflow: hidden;
  width: 100%;
  height: 600px;
}

.llinkblock {
  display: block;
}

.openseabtn {
  text-decoration: none;
}

.openseabtn.connectbutton {
  display: none;
}

.iconwallet {
  position: relative;
  z-index: 4;
  width: 32px;
  height: 32px;
  margin-left: 0px;
  background-image: url('../images/Portfolio.svg');
  background-position: 0px 0px;
  background-size: cover;
}

.dropdown {
  display: none;
  width: 32px;
  height: 32px;
  margin-left: 24px;
}

.dropdown.desktoponly {
  display: block;
}

.dropdown.desktoponly.walletbutton {
  display: none;
}

.dropdown.walleticon.walletmobilebutton {
  display: none;
}

.dropdowntoggle {
  width: 32px;
  height: 32px;
  padding: 0px;
}

.dropdowntoggle.w--open {
  height: 32px;
  padding: 0px;
}

.dropdowncontent {
  right: 10px;
}

.dropdowncontent.w--open {
  top: 49px;
  right: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #36376f;
}

.wallettitle {
  margin-top: 8px;
  padding: 0px;
  font-family: VT323, sans-serif;
  color: snow;
  font-size: 20px;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 99px;
  background-color: #f109c3;
  background-image: url('../images/Avatar.svg');
  background-position: 50% 50%;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}

.walletid {
  margin-top: 8px;
  margin-bottom: 20px;
  padding: 0px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 16px;
}

.gamesample.sampleone {
  width: 19vw;
  height: 24vw;
  margin-bottom: 20px;
  background-image: url('../images/Game-00.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.gamesample.sampletwo {
  width: 20vw;
  height: 26vw;
  margin-bottom: 20px;
  background-image: url('../images/Game-37.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.gamesample.samplethree {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 19vw;
  height: 24vw;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-image: none;
  background-size: auto;
  background-repeat: repeat;
  -o-object-fit: fill;
  object-fit: fill;
}

.gameblockinfo {
  max-width: none;
  margin: 4px auto 12px;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.list-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.nfttrophy {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 194px;
  height: 300px;
  margin-right: auto;
  margin-left: auto;
  background-image: url('../images/Game-34.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.nfttrophy._2 {
  width: 194px;
  border: 1px none #000;
  background-image: url('../images/Game-33.svg');
}

.nfttrophy._1 {
  width: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: #2f263f;
  background-image: none;
}

.nfttrophy._2 {
  width: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: #2f263f;
  background-image: none;
}

.nfttrophy._3 {
  width: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: #2f263f;
  background-image: none;
}

.nfttrophy._4 {
  width: 300px;
  border-style: solid;
  border-width: 1px;
  border-color: #2f263f;
  background-image: none;
}

.unclaimedlabel {
  padding-right: 4px;
  padding-left: 4px;
  background-color: #00f2ff;
  color: #070707;
  text-transform: uppercase;
}

.spinner {
  display: inline-block;
  width: 31px;
  height: 31px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  background-image: url('../images/spinner.gif');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.loadingtext {
  max-width: none;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: VT323, sans-serif;
  color: #6c7190;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}

.modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 32px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#242335), to(#242335));
  background-image: linear-gradient(180deg, #242335, #242335);
  background-position: 0px 0px;
}

.modal.claiming {
  padding-top: 40px;
}

.heading-2 {
  margin-top: 0px;
  margin-bottom: 4px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 26px;
  line-height: 32px;
}

.aglogoiconed {
  position: relative;
  top: -27px;
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
  background-image: url('../images/webclip.png');
  background-position: 50% 50%;
  background-size: cover;
}

.modalcontent {
  max-width: 300px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.closebanner {
  position: relative;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: auto;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  background-image: url('../images/add.svg');
  background-position: 0px 0px;
  background-size: cover;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newgamelabel {
  display: block;
  padding-right: 4px;
  padding-left: 4px;
  float: none;
  clear: none;
  background-color: #00f2ff;
  color: #070707;
  text-transform: uppercase;
}

.div-block-3 {
  display: block;
}

.claimedlabel {
  display: none;
  padding-right: 4px;
  padding-left: 4px;
  float: none;
  clear: none;
  background-color: #ff004c;
  color: #070707;
  text-transform: uppercase;
}

.infobanner {
  position: fixed;
  left: 0%;
  top: 70px;
  right: 0%;
  bottom: auto;
  z-index: 6;
  display: none;
  width: 800px;
  height: auto;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  padding: 16px 20px 16px 32px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #cc003d;
  background-image: none;
  background-position: 0px 0px;
  -webkit-transform: skew(0deg, 0deg);
  -ms-transform: skew(0deg, 0deg);
  transform: skew(0deg, 0deg);
  color: #fff;
}

.background-video {
  overflow: hidden;
  width: 15vw;
  height: auto;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(4, 8, 36, 0)), color-stop(50%, rgba(4, 8, 36, 0.74)), to(#0f0c14)), url('../images/Bg-01-Artboard-1.png');
  background-image: linear-gradient(180deg, rgba(4, 8, 36, 0), rgba(4, 8, 36, 0.74) 50%, #0f0c14), url('../images/Bg-01-Artboard-1.png');
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 340px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.image-2 {
  max-width: 60%;
  margin-bottom: 20px;
  border-radius: 0px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.modalflex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.boldtext {
  color: #fff;
}

.spinnermodal {
  display: inline-block;
  width: 31px;
  height: 31px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  background-image: url('../images/spinner.gif');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.checkiconmodal {
  display: block;
  width: 31px;
  height: 31px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  background-image: url('../images/check.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.modalcontainer {
  width: 100%;
  height: 100%;
}

.modalcontainer.prizeinputtokenmodal {
  display: none;
}

.modalcontainer.modalminitingcompleted {
  display: none;
}

.modalcontainer.modalmintinginprogress {
  display: none;
}

.modalcontainer.modalclaiminginprogress {
  display: none;
}

.modalcontainer.modalclaimingcompleted {
  display: none;
}

.modalcontainer.modalclaimingfailed {
  display: none;
}

.modalcontainer.modalminitingfailed {
  display: none;
}

.modalcontainer.modalmobilenotsupported {
  display: none;
}

.modal1sttrophyimage {
  position: relative;
  top: -27px;
  width: 86px;
  height: 86px;
  margin-bottom: 0px;
  border: 4px solid #242335;
  border-radius: 8px;
  background-image: url('../images/1st-reward.gif');
  background-position: 0px 0px;
  background-size: cover;
}

.textfield {
  height: 100%;
  margin-bottom: 16px;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left: 40px;
  border: 1px none #000;
  border-radius: 0px;
  background-color: #2f2e46;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 34px;
}

.textfield::-webkit-input-placeholder {
  color: #766c9b;
}

.textfield:-ms-input-placeholder {
  color: #766c9b;
}

.textfield::-ms-input-placeholder {
  color: #766c9b;
}

.textfield::placeholder {
  color: #766c9b;
}

.textfield.prizeinputtokenid {
  height: 50px;
  padding-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.inputcontainer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.inputicon {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: 0px;
  padding-top: 2px;
  padding-left: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #383757;
}

.text-block {
  height: auto;
  margin-left: 0px;
  font-family: VT323, sans-serif;
  color: #fff;
  font-size: 40px;
  line-height: 32px;
}

.modal2ndtrophyimage {
  position: relative;
  top: -27px;
  display: none;
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
  border: 4px solid #242335;
  border-radius: 8px;
  background-image: url('../images/Second-place.gif');
  background-position: 0px 0px;
  background-size: cover;
}

.modal3rdtrophyimage {
  position: relative;
  top: -27px;
  display: none;
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
  border: 4px solid #242335;
  border-radius: 8px;
  background-image: url('../images/Third-place.gif');
  background-position: 0px 0px;
  background-size: cover;
}

.modal4thtrophyimage {
  position: relative;
  top: -27px;
  display: none;
  width: 80px;
  height: 80px;
  margin-bottom: 0px;
  border: 4px solid #242335;
  border-radius: 8px;
  background-image: url('../images/Worst-place.gif');
  background-position: 0px 0px;
  background-size: cover;
}

.infobannercontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.credits {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.06);
  list-style-type: none;
}

.listcredits {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.creditscontainer {
  position: relative;
  z-index: 2;
  display: block;
  width: 300px;
  height: auto;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}

.creditscontainer.list {
  width: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.creditimage {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 194px;
  height: 300px;
  margin-right: auto;
  margin-left: auto;
  background-image: url('../images/Game-34.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.creditimage._1 {
  width: 300px;
  margin-bottom: 32px;
  border-style: solid;
  border-width: 1px;
  border-color: #2f263f;
  background-image: none;
}

.creditinfo {
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.listcredit {
  margin-top: 0px;
  margin-bottom: 31px;
  padding-left: 0px;
}

.div-block-5 {
  position: relative;
  left: -5%;
  width: 110%;
  margin-top: 80px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 20px 64px 56px;
  background-color: #4913be;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(27, 31, 73, 0.92)), to(rgba(27, 31, 73, 0.92))), url('../images/2nd-Prize-Optimized.gif');
  background-image: linear-gradient(180deg, rgba(27, 31, 73, 0.92), rgba(27, 31, 73, 0.92)), url('../images/2nd-Prize-Optimized.gif');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, contain;
  background-repeat: repeat, repeat;
}

.winnerwalletidlink {
  color: #fff;
  text-decoration: underline;
}

.gamessamplecontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 32px;
  padding-left: 32px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.gamelistitemcontent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nftlistitem {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  max-width: none;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  float: left;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none hsla(0, 0%, 100%, 0.06);
  list-style-type: none;
}

.erroriconmodal {
  display: block;
  width: 31px;
  height: 31px;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  background-image: url('../images/error.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.errormessage {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.text-block-2 {
  display: none;
  height: 0px;
  font-family: VT323, sans-serif;
  color: #f8f8f8;
  font-size: 18px;
}

.success-message {
  position: relative;
  height: 0px;
  padding: 0px;
  background-color: transparent;
}

.text-block-3 {
  display: none;
}

.form-block {
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .body {
    overflow: hidden;
  }

  .brand.test {
    width: 80px;
  }

  .navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 72px;
    padding-right: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navmenu {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100%;
    margin-top: 0px;
    padding-top: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #282033;
  }

  .header {
    padding-right: 60px;
    padding-left: 60px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background-position: 0px 0px, 50% 50%, 50% 50%;
  }

  .containercontent.list {
    padding-right: 40px;
    padding-left: 40px;
  }

  .containercontent.page {
    padding-right: 40px;
    padding-left: 40px;
  }

  .containercontent.creditspage {
    padding-right: 40px;
    padding-left: 40px;
  }

  .buttoncontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .button.nav {
    position: relative;
    display: none;
    margin-right: 20px;
  }

  .button.nav.mobile {
    display: block;
    margin-right: 0px;
  }

  .button.banner {
    position: relative;
    display: none;
    margin-right: 20px;
  }

  .button.banner.mintbutton {
    display: block;
    margin-right: 0px;
  }

  .button.banner.mint5button {
    display: block;
  }

  .button.opensealink {
    position: relative;
    display: block;
    margin-right: 20px;
  }

  .contentsection2 {
    padding-right: 20px;
    padding-left: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/Bg-01-Artboard-1.png');
    background-image: linear-gradient(180deg, null, null), url('../images/Bg-01-Artboard-1.png');
    background-position: 0px 0px, 50% 50%;
  }

  .sectionheading {
    font-size: 8vw;
  }

  .animation {
    top: 480px;
  }

  .sectioncontent.rulessection {
    margin-top: 40px;
    padding-right: 60px;
    padding-left: 60px;
  }

  .footer {
    background-color: #141318;
  }

  .topfooter {
    padding-right: 20px;
    padding-left: 20px;
  }

  .navlinks {
    left: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .menubutton {
    z-index: 5;
    display: block;
    height: 100%;
    padding: 0px 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-family: VT323, sans-serif;
    color: #fff;
  }

  .menubutton.w--open {
    z-index: 99;
    background-color: transparent;
  }

  .spacer {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .icon {
    position: relative;
    top: 50%;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
  }

  .overlay {
    display: none;
  }

  .main {
    background-size: auto, contain;
  }

  .gameinfo {
    padding-right: 0px;
  }

  .heading3.small {
    font-size: 20px;
    line-height: 24px;
  }

  .listicon {
    float: left;
  }

  .listitem {
    height: auto;
  }

  .listitemtext {
    max-width: none;
    margin-bottom: 8px;
    float: left;
  }

  .upsellbanner {
    width: auto;
    padding-right: 0px;
  }

  .gamedescription {
    padding-bottom: 40px;
    padding-left: 0px;
  }

  .aglogo {
    display: block;
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
  }

  .div-block-2 {
    height: auto;
  }

  .div-block-2.three {
    display: none;
  }

  .section {
    padding-right: 32px;
    padding-left: 32px;
  }

  .llinkblock.mobile-hideen.connectbutton {
    display: none;
  }

  .llinkblock.connectbutton {
    display: block;
  }

  .dropdown {
    display: block;
  }

  .dropdown.desktoponly {
    display: none;
    margin-left: 0px;
  }

  .dropdown.walleticon {
    display: none;
  }

  .gamesample.sampleone {
    width: 210px;
    height: 320px;
  }

  .gamesample.sampletwo {
    width: 210px;
    height: 320px;
  }

  .infobanner {
    width: auto;
    margin-right: 32px;
    margin-left: 32px;
  }

  .modalcontainer.modalmintinginprogress {
    display: none;
  }

  .modalcontainer.modalclaiminginprogress {
    display: none;
  }

  .modalcontainer.modalclaimingfailed {
    display: none;
  }

  .modalcontainer.modalmobilenotsupported {
    display: none;
  }

  .inputicon {
    margin-left: 0px;
  }

  .gamessamplecontainer {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

@media screen and (max-width: 767px) {
  .body {
    overflow: visible;
  }

  .body.bg {
    overflow: visible;
  }

  .brand {
    width: 59px;
    height: 72px;
  }

  .brand.test {
    width: 60px;
    margin-top: 0px;
  }

  .navbar {
    height: 72px;
  }

  .header {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, rgba(15, 12, 20, 0.22)), color-stop(34%, rgba(15, 12, 20, 0.74)), to(#0f0c14)), url('../images/Untitled-01-Artboard-1.jpg');
    background-image: linear-gradient(180deg, rgba(15, 12, 20, 0.22) 2%, rgba(15, 12, 20, 0.74) 34%, #0f0c14), url('../images/Untitled-01-Artboard-1.jpg');
    background-position: 0px 0px, 50% 0%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }

  .header.test {
    padding-right: 32px;
    padding-left: 32px;
  }

  .containercontent {
    padding-top: 110px;
  }

  .containercontent.list {
    display: block;
    padding-right: 60px;
    padding-left: 60px;
  }

  .containercontent.page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 60px;
    padding-left: 60px;
  }

  .containercontent.creditspage {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 60px;
    padding-left: 60px;
  }

  .buttoncontainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .buttoncontainer.gamesample {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .button.nav {
    display: none;
  }

  .button.nav.mobile {
    display: block;
  }

  .button.large {
    min-width: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .button.large.claimprize1button {
    width: auto;
  }

  .button.large.claimprize2button {
    width: auto;
  }

  .button.large.claimprize3button {
    width: auto;
  }

  .button.large.claimprize4button {
    width: auto;
  }

  .button.banner.mintbutton {
    display: block;
    margin-bottom: 12px;
  }

  .button.banner.mint5button {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
  }

  .button.opensealink {
    margin-right: auto;
  }

  .contentsection2 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/Bg-01-Artboard-1.png');
    background-image: linear-gradient(180deg, null, null), url('../images/Bg-01-Artboard-1.png');
    background-position: 0px 0px, 100% 100%;
  }

  .animation {
    top: 560px;
  }

  .sectioncontent {
    padding-right: 20px;
    padding-left: 20px;
  }

  .sectioncontent.rulessection {
    margin-top: 100px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .footer {
    padding-top: 20px;
    background-color: #141318;
  }

  .topfooter {
    padding-bottom: 20px;
  }

  .footercolumn {
    margin-bottom: 32px;
  }

  .containertopfooter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(17%, #0f0b16), color-stop(72%, rgba(18, 13, 31, 0.78)), color-stop(99%, #0a0716)), url('../images/spacebg.jpeg');
    background-image: linear-gradient(180deg, #0f0b16 17%, rgba(18, 13, 31, 0.78) 72%, #0a0716 99%), url('../images/spacebg.jpeg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, contain;
  }

  .gamelistitem {
    display: none;
    margin-bottom: 56px;
  }

  .gameinfo {
    margin-top: 32px;
    padding-left: 0px;
  }

  .heading3.small {
    font-size: 17px;
    line-height: 19px;
  }

  .gamelist {
    display: block;
  }

  .listitemtext {
    max-width: none;
  }

  .gameasset.loading {
    margin-right: 0px;
    margin-left: 0px;
  }

  .vspaces {
    height: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .upsellbanner {
    display: block;
    width: auto;
    height: auto;
    padding: 24px;
  }

  .infobannercopy {
    margin-right: 13px;
  }

  .infobannercopy.leftaligned {
    margin-bottom: 20px;
    text-align: center;
  }

  .aglogo {
    display: block;
    width: 70%;
    margin-bottom: 10px;
  }

  .logocontainer {
    display: block;
  }

  .div-block-2 {
    height: auto;
  }

  .buttonsecondary.large {
    min-width: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .buttonsecondary.large.viewonopensea {
    width: 100%;
  }

  .llinkblock.mobile-hideen {
    display: none;
  }

  .llinkblock.mobile-hideen.connectbutton {
    display: none;
  }

  .llinkblock.connectbutton {
    display: block;
  }

  .dropdown.walleticon.walletmobilebutton {
    display: none;
  }

  .gamesample.sampleone {
    width: 180px;
    height: 280px;
    font-size: 14px;
  }

  .gamesample.sampletwo {
    width: 180px;
    height: 280px;
  }

  .list-3 {
    max-width: 300px;
  }

  .infobanner {
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .inputicon {
    margin-left: 0px;
  }

  .credits {
    display: block;
    margin-bottom: 56px;
  }

  .listcredits {
    display: block;
  }

  .creditinfo {
    margin-top: 32px;
    padding-left: 0px;
  }

  .div-block-5 {
    padding-right: 34px;
    padding-left: 34px;
  }

  .gamelistitemcontent {
    display: block;
  }

  .nftlistitem {
    display: block;
    margin-right: auto;
    margin-bottom: 56px;
    margin-left: auto;
  }
}

@media screen and (max-width: 479px) {
  .body {
    overflow: visible;
  }

  .body.bg {
    overflow: visible;
  }

  .brand {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .brand.test {
    width: 60px;
    height: 70px;
    margin-top: 0px;
  }

  .navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .navmenu {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .header {
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(15, 12, 20, 0)), color-stop(34%, rgba(15, 12, 20, 0.74)), to(#0f0c14)), url('../images/Untitled-01-Artboard-1.jpg');
    background-image: linear-gradient(180deg, rgba(15, 12, 20, 0), rgba(15, 12, 20, 0.74) 34%, #0f0c14), url('../images/Untitled-01-Artboard-1.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, repeat;
  }

  .header.test {
    padding-right: 16px;
    padding-left: 16px;
  }

  .heading {
    font-size: 12vw;
    line-height: 16vw;
  }

  .containercontent {
    padding-right: 20px;
    padding-left: 20px;
  }

  .containercontent.list {
    padding-top: 24px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .containercontent.page {
    display: block;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .containercontent.creditspage {
    display: block;
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .buttoncontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .buttoncontainer.gamesample {
    position: relative;
    z-index: 4;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .button.nav {
    display: none;
  }

  .button.nav.mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
  }

  .button.compact {
    width: 100%;
    min-width: 0px;
  }

  .button.large.claimprize1button {
    width: 100%;
  }

  .button.large.claimprize2button {
    width: 100%;
  }

  .button.large.claimprize3button {
    width: 100%;
  }

  .button.large.claimprize4button {
    width: 100%;
  }

  .button.bottom {
    width: 100%;
    min-width: 0px;
  }

  .button.banner {
    display: none;
  }

  .button.banner.mintbutton {
    margin-right: 0px;
  }

  .button.banner.mint5button {
    margin-right: 0px;
  }

  .button.opensealink {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
  }

  .contentsection2 {
    top: -37px;
    margin-top: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(30, 23, 53, 0.79)), to(rgba(30, 23, 53, 0.79))), url('../images/Bg-01-Artboard-1.png');
    background-image: linear-gradient(180deg, rgba(30, 23, 53, 0.79), rgba(30, 23, 53, 0.79)), url('../images/Bg-01-Artboard-1.png');
    background-position: 0px 0px, 100% 100%;
    background-size: auto, auto;
    background-repeat: repeat, no-repeat;
  }

  .sectionheading {
    line-height: 14vw;
  }

  .animation {
    top: 680px;
  }

  .sectioncontent {
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .sectioncontent.rulessection {
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .footer {
    padding-top: 20px;
    background-color: #141318;
    background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('../images/Bg-01-Artboard-1.png');
    background-image: linear-gradient(180deg, null, null), url('../images/Bg-01-Artboard-1.png');
    background-size: auto, cover;
  }

  .topfooter {
    padding-bottom: 20px;
  }

  .bottomfooter {
    background-color: #101013;
  }

  .footercolumn {
    margin-bottom: 32px;
  }

  .containertopfooter {
    display: block;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .counter {
    margin-bottom: 4px;
  }

  .navlinks {
    margin-top: 20px;
  }

  .overlay {
    overflow: hidden;
  }

  .main {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(17%, #0f0b16), color-stop(72%, rgba(18, 13, 31, 0.78)), color-stop(99%, #0a0716)), url('../images/spacebg.jpeg');
    background-image: linear-gradient(180deg, #0f0b16 17%, rgba(18, 13, 31, 0.78) 72%, #0a0716 99%), url('../images/spacebg.jpeg');
    background-size: auto, contain;
  }

  .gamelistitem {
    display: none;
  }

  .gameinfo {
    padding-right: 0px;
  }

  .listicon {
    position: static;
    float: left;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .listitem {
    display: block;
    overflow: visible;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .listitem.loading {
    height: 32px;
  }

  .listitem.loading.short {
    height: 32px;
  }

  .listitemtext {
    overflow: visible;
    width: auto;
    max-width: none;
    float: left;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    white-space: normal;
  }

  .image2 {
    position: relative;
    width: 24px;
    height: 24px;
    float: none;
  }

  .headingm {
    font-size: 40px;
    line-height: 40px;
  }

  .upsellbanner {
    display: block;
    height: auto;
    padding-top: 16px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .infobannercopy {
    margin-bottom: 10px;
    text-align: left;
  }

  .infobannercopy.leftaligned {
    text-align: center;
  }

  .gamedescription {
    padding-bottom: 20px;
  }

  .aglogo {
    width: 80%;
    max-width: none;
    margin-bottom: 20px;
  }

  .logocontainer {
    width: auto;
  }

  .textblock3 {
    text-decoration: none;
  }

  .decoration {
    left: 284px;
    top: -20%;
    right: 0px;
  }

  .headercontainer {
    padding-top: 120px;
  }

  .div-block-2 {
    height: auto;
  }

  .div-block-2.two {
    display: none;
  }

  .buttonsecondary.large.openseabutton {
    width: 100%;
  }

  .section {
    position: relative;
    display: block;
    overflow: visible;
    height: auto;
    max-width: none;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .backgrounddecorations {
    left: 0px;
    top: auto;
    right: 0%;
    bottom: -150px;
    overflow: hidden;
    width: 100%;
    height: 320px;
  }

  .llinkblock {
    display: none;
    text-decoration: none;
  }

  .llinkblock.mobile-hideen {
    display: block;
  }

  .llinkblock.mobile-hideen.connectbutton {
    display: block;
  }

  .llinkblock.connectbutton {
    display: none;
  }

  .dropdowncontent.w--open {
    left: -111px;
    right: 0px;
  }

  .gamesample.sampleone {
    height: 280px;
  }

  .list-3 {
    margin-right: auto;
    margin-left: auto;
  }

  .nfttrophy._1 {
    width: 100%;
    height: auto;
    background-image: none;
  }

  .nfttrophy._2 {
    width: 100%;
    height: 100%;
    background-image: none;
  }

  .nfttrophy._3 {
    width: 100%;
    height: 100%;
  }

  .nfttrophy._4 {
    width: 100%;
    height: 100%;
    background-image: none;
  }

  .loadingtext {
    overflow: visible;
    max-width: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .modal {
    padding-right: 16px;
    padding-left: 16px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .heading-2 {
    font-size: 7.5vw;
    text-align: center;
  }

  .modalcontent {
    max-width: 260px;
  }

  .infobanner {
    margin-right: 16px;
    margin-left: 16px;
    padding-left: 16px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .utility-page-content {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
  }

  .inputicon {
    margin-left: 0px;
  }

  .creditimage._1 {
    width: 100%;
    height: auto;
    background-image: none;
  }

  .creditinfo {
    padding-right: 0px;
  }

  .div-block-5 {
    left: 0%;
    width: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}

